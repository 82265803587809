<template>
  <vx-card class="top-zero-radius main-box md:w-1/2 mx-auto" no-shadow>

    <div class="mb-base">
      <h6 class="mb-4">{{ $t('setting.Locale.labels.basicSetting') }}</h6>

      <span>{{ $t('setting.Locale.labels.defaultLanguage') }}</span>
      <custom-select class="md:w-1/3"
                     :options="languages"
                     :default="locale"
                     v-model="locale"/>
    </div>
    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button id="saveBTN" class="useral-action-button ml-auto mt-2" color="success" @click="updateLocale">{{ $t('setting.buttons.save') }}</vs-button>
    </div>
  </vx-card>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomSelect from '@/components/customSelect/customSelect'

export default {
  name: 'LocaleSetting',
  components: {CustomSelect},
  metaInfo () {
    return {
      title: this.$t('setting.Locale.title')
    }
  },
  data () {
    return {
      locale: {},
      languages: [
        {
          value: 'en',
          label: 'English',
          flag: 'en.png'
        },
        {
          value: 'fa',
          label: 'فارسی',
          flag: 'fa.png'
        }
      ],
      actions: {
        toolbar: [
          {
            id: 'saveBTN',
            icon: 'icon-check',
            iconPack: 'feather',
            color: 'success'
          }
        ],
        leftToolbar: [
          {
            id: {name: 'Settings'},
            type: 'link',
            icon: 'icon-chevron-left',
            iconPack: 'feather'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      currentLocale: 'currentLocale'
    })
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
    }, 50)

    this.setCurrentUserLocale()
  },
  methods: {
    updateLocale () {
      this.$i18n.locale = this.locale
      localStorage.setItem('userLocale', this.locale.value)
      if (this.locale === 'en') {
        this.$vs.rtl = false
      } else if (this.locale === 'fa') {
        this.$vs.rtl = true
      }
      location.reload()
    },
    setCurrentUserLocale () {
      const locale = this.$i18n.locale
      this.languages.forEach((language) => {
        if (locale === language.value) this.locale = language
      })
    },
    removeDuplicates () {
      const val = this.locale
      /*let languages = [
        {
          value: 'en',
          label: 'English',
          flag: 'en.png'
        },
        {
          value: 'fa',
          label: 'فارسی',
          flag: 'fa.png'
        },
      ]
      for(let i = 0 ; i < languages.length ; i++) {
        if (languages[i].value === val.value || languages[i].value === val)
          languages.splice(i, 1)
      }
      this.languages = languages*/
    }
  },
  watch: {
    locale: {
      handler (val, oldVal) {
        this.removeDuplicates()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
